import React from 'react';
import './scanner-register.css';
import {
  clearScannerRegisterPage,
  updateScannerSelected,
  updateScannerFilter,
  updateScannerEndDate
} from '../../../actions/activitiesPageActions/scannerRegisterActions';
import { connect } from 'react-redux';
import Loading from '../../loading/loading';
import Page from '../../utils/page/page.jsx';
import ScrollView from '../../utils/scrollView/scrollView.jsx';

class ScannerRegister extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      scannerList: [],
      scannerQueryDone: false
    };
  }

  componentWillUnmount() {
    // this.props.clearScannerRegisterPage();
  }

  componentDidMount() {
    if (this.props.filter !== this.props.name) {
      var name = this.props.name;
      if (name === '') {
        name = 'No Scanner';
      }
      this.props.updateScannerFilter(name);
    }

    this.props.io.get('/raspberrypi', (resData) => {
      const list = [];
      const noScannerOption = {
        createdAt: 0,
        id: 0,
        mac: '',
        nickname: 'No Scanner',
        updatedAt: 0
      };
      list.push(noScannerOption);

      if (resData.length) {
        list.push(...resData);
      }

      this.setState({
        scannerList: list,
        scannerQueryDone: true
      });
    });
  }

  backButtonAction() {
    var requiredStateSet =
      this.props.selectActivity.activityCitySelected !== 0 &&
      this.props.selectActivity.activitySelected !== 0 &&
      this.props.selectActivity.activitySiteSelected !== 0;

    if (this.props.filter === 'No Scanner') {
      this.props.updateScannerSelected('');
      this.props.updateScannerEndDate(null);
    }

    this.props.history.push('/activities/home');
  }

  getActivityName() {
    var fullName = this.props.selectActivity.activityName;

    if (this.props.selectActivity.activityDetails.length > 0) {
      const detailNames = this.props.selectActivity.activityDetails.map(
        (detail) => detail.activityDetail
      );
      if (detailNames.length === 1) {
        fullName = fullName + ' - ' + detailNames.join('');
      } else {
        fullName = fullName + ' - ' + detailNames.join(', ');
      }
    }

    if (this.props.selectActivity.activityProvidersSelected !== 0) {
      fullName = fullName + ' (' + this.props.selectActivity.activityProviders + ')';
    }
    fullName =
      fullName +
      ', ' +
      this.props.selectActivity.activityCity +
      ' - ' +
      this.props.selectActivity.activitySite;

    return fullName;
  }

  async registerButtonAction() {
    if (this.props.filter !== 'No Scanner') {
      this.props.updateScannerSelected(this.props.filter);
      const currentDate = new Date();
      const endDate = new Date(currentDate.getTime() + 60 * 60 * 1000);

      const activityTitle = this.getActivityName();
      var assignedScannerModel = {
        nickname: this.props.filter,
        activityTitle: activityTitle,
        uuid: this.props.uuid,
        assignmentEndDate: endDate
      };

      await this.props.io.post(
        '/raspberrypi/assignscanner',
        assignedScannerModel,
        (data, jwres) => {
          if (jwres.statusCode === 200) {
            this.props.updateScannerEndDate(new Date(data.assignmentEndDate));
          } else {
            console.error('Error:', data);
          }
        }
      );
    }
    this.props.history.push('/activities/home');
  }

  renderScannerOptions() {
    const scannerOptions = {
      columns: [{ width: 100 }],
      titles: ['Nickname'],
      entries: [],
      selectEvent: this.props.updateScannerFilter,
      selected: this.props.filter
    };
    this.state.scannerList.forEach((scanner) => {
      scannerOptions.entries.push({
        id: scanner.nickname,
        columns: [scanner.nickname]
      });
    });
    return scannerOptions;
  }

  renderScannerButtons() {
    return {
      left: {
        title: 'Home',
        onClick: () => this.backButtonAction()
        // disabled: this.props.assignmentEndDate === null
      },
      right: {
        title: 'Assign Scanner',
        onClick: () => this.registerButtonAction(),
        disabled: this.props.filter === ''
      }
    };
  }

  render() {
    if (!this.state.scannerQueryDone) {
      return <Loading />;
    }
    return (
      <Page>
        <ScrollView options={this.renderScannerOptions()} buttons={this.renderScannerButtons()} />
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  io: state.application.io,
  uuid: state.auth.uuid,
  name: state.activities.scanner.name,
  filter: state.activities.scanner.searchFilter,
  assignmentEndDate: state.activities.scanner.assignmentEndDate,
  selectActivity: state.activities.selectActivity
});

export default connect(mapStateToProps, {
  clearScannerRegisterPage,
  updateScannerSelected,
  updateScannerFilter,
  updateScannerEndDate
})(ScannerRegister);
